<template>
  <b-card
    no-body
  >
    <b-overlay
      :show="false"
      rounded="sm"
    >
      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <!-- Search -->
          <b-col
            cols="12"
            md="12"
            class="px-0 px-md-1"
          >
            <div class="d-flex align-items-center justify-content-end font-weight-bolder">
              <b-button
                variant="primary"
                @click="showBusinessCardForm('create')"
              >
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                /> &nbsp;
                Add Card
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <vue-good-table
        mode="remote"
        :pagination-options="{
          enabled: true,
          perPage: serverParams.perPage,
          perPageDropdown: [5, 10, 20, 50, 100],
          dropdownAllowAll: false,
          mode: 'pages',
        }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: false, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :is-loading="isLoading"
        :total-rows="totalRecords"
        :rows="businessCards"
        :columns="columnDefs"

        max-height="60vh"
        :fixed-header="true"
        styleClass="vgt-table condensed bordered"
        compactMode

        @on-page-change="onPageChange"
        @on-sort-change="onSortChange"
        @on-column-filter="onColumnFilter"
        @on-per-page-change="onPerPageChange"
        @on-selected-rows-change="onSelectionChange"
      >

        <template
          slot="column-filter"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'created_on'"
            class="mt-2"
          >
            <flat-pickr
              v-model="created_on_filter"
              :config="configPicker"
              class="vgt-input"
              placeholder="Filter Added On"
              @input="(value) => updateFilters(props.column.field, value)"
            />
          </span>
        </template>

        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: Status -->
          <span v-if="props.column.field === 'front_image'">
            <!-- <span>
              <img
                class="fade-in-image"
                fluid
                :src="data.item.front_image || $defaultCardFrontImage"
                width="150"
                height="100"
              >
            </span> -->
            <div class="flip-card" style="height:100px;">
              <div
                ref="refsFlipCardInner"
                class="flip-card-inner"
                :class="{'flip-back' : displayCardBackSide[props.row.id]}"
              >
                <div class="flip-card-front">
                  <img
                    ref="refFrontImageImg"
                    class="cursor-pointer"
                    fluid
                    :src="props.row.front_image || $defaultCardFrontImage"
                    width="150"
                    height="100"
                    data-placement="left"
                    data-toggle="tooltip"
                    title="Click to flip the card"
                    @click="flipCard(props.row.id)"
                  >
                </div>
                <div class="flip-card-back">
                  <img
                    ref="refBackImageImg"
                    class="cursor-pointer"
                    fluid
                    :src="props.row.back_image || $defaultCardBackImage"
                    width="150"
                    height="100"
                    data-placement="left"
                    data-toggle="tooltip"
                    title="Click to flip the card"
                    @click="flipCard(props.row.id)"
                  >
                </div>
              </div>
            </div>
          </span>

          <div v-else-if="props.column.field === 'actions'" class="d-flex align-items-center justify-content-start" style="height:100px;">
            <div class="text-nowrap">
              <feather-icon
                v-b-tooltip.hover.topleft="`Edit / View card details`"
                icon="EditIcon"
                size="25"
                class="mx-1 text-primary cursor-pointer"
                @click="showBusinessCardForm('edit', props.row.id, false)"
              />

              <feather-icon
                v-b-tooltip.hover.topleft="`Go to card page`"
                icon="GlobeIcon"
                size="25"
                class="mx-1 text-primary cursor-pointer"
                @click="redirectTo(props.row)"
              />

              <!-- <b-button
                v-if="!['paid'].includes(props.row.stripe_payment_status)"
                variant="primary"
                class="mx-1"
                @click="showBusinessCardForm('edit', props.row.id, true)"
              >
                Complete Payment
              </b-button> -->
            </div>
            <stripe-checkout
              v-if="!['paid'].includes(props.row.stripe_payment_status) && props.row.id && showPaymentButton"
              ref="stripePlugin"
              :create-session-endpoint="`/api/business-cards/${props.row.id}/create-session`"
              @is-loading="isLoading = $event"
            />
          </div>

          <div v-else-if="props.column.field === 'landing_page_type'" class="d-flex align-items-center" style="height:100px;">
            <b-badge
              v-if="props.row.landing_page_type === 'dynamic'"
              pill
              variant="warning"
            >
              Dynamic
            </b-badge>

            <b-badge
              v-else-if="props.row.landing_page_type === 'static'"
              pill
              variant="success"
            >
              Static
            </b-badge>
          </div>

          <!-- <span v-else-if="props.column.field === 'status'"> -->
          <div v-else-if="props.column.field === 'status'" class="d-flex align-items-center" style="height:100px;">
            <label class="switch">
              <input
                :checked="props.row.deactivated_at === null"
                type="checkbox"
                @click="toggleStatus($event, props.row.id)"
              >
              <span class="slider round" />
            </label>
            <span
              class="text-nowrap pl-1"
              :class="props.row.deactivated_at === null ? 'text-success' : 'text-danger'"
            >
              {{ props.row.deactivated_at === null ? 'Active' : 'Deactivated' }}
            </span>
          </div>

          <div v-else-if="props.column.field === 'stripe_payment_status'" class="d-flex align-items-center" style="height:100px;">
            <b-badge
              v-if="props.row.stripe_payment_status === 'succeeded'"
              pill
              class="h-6 my-auto"
              variant="success"
            >
              {{ props.row.stripe_payment_status }}
            </b-badge>
          </div>

          <!-- Column: Common -->
          <div v-else class="d-flex align-items-center" style="height:100px;">
            {{ props.formattedRow[props.column.field] }}
          </div>
        </template>
      </vue-good-table>

      <b-table
        v-if="false"
        ref="refBusinessCardListTable"
        :items="businessCards"
        responsive
        sticky-header
        :fields="tableColumns"
        primary-key="id"
        show-empty
        empty-text="No matching records found"
        class="position-relative business-card-list-table"
      >
        <template #cell(front_image)="data">
          <!-- <span>
            <img
              class="fade-in-image"
              fluid
              :src="data.item.front_image || $defaultCardFrontImage"
              width="150"
              height="100"
            >
          </span> -->
          <div class="flip-card" style="height:100px;">
            <div
              ref="refsFlipCardInner"
              class="flip-card-inner"
              :class="{'flip-back' : displayCardBackSide[data.item.id]}"
            >
              <div class="flip-card-front">
                <img
                  ref="refFrontImageImg"
                  class="cursor-pointer"
                  fluid
                  :src="data.item.front_image || $defaultCardFrontImage"
                  width="150"
                  height="100"
                  data-placement="left"
                  data-toggle="tooltip"
                  title="Click to flip the card"
                  @click="flipCard(data)"
                >
              </div>
              <div class="flip-card-back">
                <img
                  ref="refBackImageImg"
                  class="cursor-pointer"
                  fluid
                  :src="data.item.back_image || $defaultCardBackImage"
                  width="150"
                  height="100"
                  data-placement="left"
                  data-toggle="tooltip"
                  title="Click to flip the card"
                  @click="flipCard(data)"
                >
              </div>
            </div>
          </div>
        </template>

        <!-- Column: Card Number -->
        <template #cell(card_number)="data">
          <span class="text-nowrap">
            {{ data.item.card_number }}
            <!-- {{ data.item.user_id }}-{{ data.item.id }} -->
          </span>
        </template> 

        <!-- Column: active_link -->
        <template #cell(active_link)="data">
          <span class="text-nowrap">
            {{ data.item.active_link || '-' }}
          </span>
        </template> 

        <!-- Column: Status -->
        <template #cell(status)="data">
          <label class="switch">
            <input
              :checked="data.item.deactivated_at === null"
              type="checkbox"
              @click="toggleStatus($event, data.item.id)"
            >
            <span class="slider round" />
          </label>
          <span
            class="text-nowrap pl-1"
            :class="data.item.deactivated_at === null ? 'text-success' : 'text-danger'"
          >
            {{ data.item.deactivated_at === null ? 'Active' : 'Deactivated' }}
          </span>
        </template>

        <template #cell(stripe_payment_status)="data">
          <b-badge
            v-if="data.item.stripe_payment_status === 'succeeded'"
            pill
            class="h-6"
            variant="success"
          >
            {{ data.item.stripe_payment_status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">

          <div class="text-nowrap">

            <feather-icon
              v-b-tooltip.hover.topleft="`Edit / View card details`"
              icon="EditIcon"
              size="18"
              class="mx-1 text-primary cursor-pointer"
              @click="showBusinessCardForm('edit', data.item.id)"
            />

            <feather-icon
              v-b-tooltip.hover.topleft="`Go to card page`"
              icon="GlobeIcon"
              size="18"
              class="mx-1 text-primary cursor-pointer"
              @click="showBusinessCardForm('edit', data.item.id)"
            />

            <!-- <feather-icon
              v-b-tooltip.hover.topleft="`Preview Invoice`"
              icon="EyeIcon"
              size="16"
              class="mx-1"
              @click="$router.push({ name: 'business-cards-view', params: { id: data.item.id }})"
            /> -->

            <!-- Dropdown -->
            <!-- <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                @click="showBusinessCardForm('edit', data.item.id)"
              >
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item>
                <feather-icon icon="TrashIcon" />
                <span class="align-middle ml-50">Deactivate</span>
              </b-dropdown-item>
            </b-dropdown> -->
          </div>
        </template>

      </b-table>
    </b-overlay>

    <div class="mx-2 mb-2">
      <b-row>

        <!-- <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col> -->

        <!-- Pagination -->
        <!-- <b-col
          cols="12"
          class="d-flex align-items-center justify-content-end justify-content-sm-end"
        >

          <b-pagination
            v-model="pagination.currentPage"
            :total-rows="pagination.totalRows"
            :per-page="pagination.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col> -->

      </b-row>
    </div>

    <b-modal
      v-model="popupBusinessCardForm"
      centered
      hide-footer
      :title="`${$capitalizeString(formType)} a Business Card`"
      size="lg"
      style="min-height: 800px;"
    >
      <business-card-form
        :card-id="cardId"
        :initial-form-type="formType"
        :show-payment-step="showPaymentStep"
				:parent-is-loadint="isLoading"
        @close-modal="popupBusinessCardForm = !popupBusinessCardForm"
        @refresh-record="getBusinessCards"
      />
    </b-modal>
  </b-card>
</template>

<script>
// import { VueGoodTable } from 'vue-good-table'
import { businessCardFormMixins } from '../mixins/formMixins'
import StripeCheckout from '../../stripe/stripe-checkout.vue'

export default {
  components: {
    StripeCheckout,
  },
  mixins: [
    // VueGoodTable,
    businessCardFormMixins,
  ],
  data() {
    return {
      isLoading: false,
      showPaymentButton: true,

      businessCards: [],
      tableColumns: [
        { key: 'front_image', label: 'Card Style', sortable: false, thStyle: { width: '15%' }},
        // { key: 'card_number', label: 'Card Number', sortable: true, thStyle: { width: '15%' } },
        // { key: 'static_link', label: 'Static Link', sortable: true, thStyle: { width: '28%' } },
        { key: 'active_link', label: 'Active Link', sortable: true, thStyle: { width: '15%' } },
        { key: 'status', label: 'status', sortable: true, thStyle: { width: '15%' } },
        // { key: 'stripe_payment_status', label: 'Payment Status', sortable: true, thStyle: { width: '10%' } },
        { key: 'actions', thStyle: { width: '5%' } },
      ],

      // pagination
      perPageOptions: [5, 10, 20, 40],
      pagination: {
        currentPage: 1,
        totalRows: 1,
        perPage: 5,
      },

      // flip-card
      displayCardBackSide: {},

      // vue-good-table
      columnDefs: [
        {
          label: 'Card Style',
          field: 'front_image',
          filterOptions: {
            trigger: 'enter',
            enabled: false,
            placeholder: '',
          },
          sortable: false,
          width: '20%',
        },
        {
          label: 'Landing Page',
          field: 'active_link',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',
          },
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            trigger: 'enter',
            enabled: true,
            placeholder: '',

            filterDropdownItems: [
              { value: 'active', text: 'Active' },
              { value: 'deactivated', text: 'Deactivated' },
            ],
          },
        },
        {
          label: 'Actions',
          field: 'actions',
          sortable: false,
          width: '25%',
        },
      ],
      serverParams: {
        columnFilters: {},
        sort: {},
        page: 1,
        perPage: 5,
      },
      selectedRows: null,
      totalRecords: 0,
    }
  },
  watch: {
    // server pagination
    'pagination.currentPage': function (val, oldVal) {
      this.getBusinessCards()
    },
    'pagination.perPage': function (val, oldVal) {
      this.getBusinessCards()
    },
  },
  created() {
		console.log(this.$route.query)
		if (this.$route.query.stripe_status === 'failed') {
			this.$toastDanger('Stripe Operation', 'Transaction unsuccessful')
			this.$router.replace({'query': null});
		} else if (this.$route.query.stripe_status === 'success') {
			this.$toastSuccess('Stripe Operation', 'Transaction success')
			this.$router.replace({'query': null});
		}

    this.getBusinessCards()
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
      this.getBusinessCards()
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage })
      this.showPaymentButton = false
      setTimeout(() => {
        this.showPaymentButton = true
      }, 100)
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage })
    },
    onSortChange(params) {
      if (params[0]) {
        const fieldName = params[0].field
        const sortDetails = { }
        sortDetails[fieldName] = params[0].type
        this.updateParams({ sort: sortDetails })
      }
    },
    onColumnFilter(params) {
      this.serverParams.page = 1
      this.updateParams(params)
    },
    onSelectionChange(params) {
      this.selectedRows = params.selectedRows
    },
    updateFilters(column, value) {
      const currentFilters = this.serverParams.columnFilters
      currentFilters[column] = value
      this.updateParams({ columnFilters: currentFilters })
    },

    getBusinessCards() {
      this.isLoading = true

      let query = ''
      // query += `${this.$attachQuerySymbol(query)}pagination=${JSON.stringify(this.pagination)}`
      query = `?params=${JSON.stringify(this.serverParams)}`

      // if (this.filters && Object.keys(this.filters).length > 0) {
      //   query += `${this.$attachQuerySymbol(query)}filters=${JSON.stringify(this.filters)}`
      // }

      // if (this.sorters && Object.keys(this.sorters).length > 0) {
      //   query += `${this.$attachQuerySymbol(query)}sorters=${JSON.stringify(this.sorters)}`
      // }

      // if (this.columns && this.columns.length > 0) {
      //   query += `${this.$attachQuerySymbol(query)}columns=${JSON.stringify(this.columns)}`
      // }

      this.$http
        .get(`/api/business-cards${query}`)
        // .get(`/api/business-cards`)
        .then(response => {
          // const { success, message, output, pagination } = response.data
          const { success, message, output, totalRecords } = response.data
          this.businessCards = []
          this.businessCards = output
          this.totalRecords = totalRecords

          this.isLoading = false
        })
        .catch(error => {
          this.$handleResponseError(error, () => { this.isLoading = false })
        })

    },
    toggleStatus(event, id) {
      let status = 'deactivate'
      if (event.target.checked) {
        status = 'activate'
      }

      this.$http
        .put(`/api/business-cards/${id}/${status}`)
        .then(response => {
          this.$toastSuccess('Changes Saved', 'Your changes have been saved')

          this.getBusinessCards()
        })
        .catch(error => {
          this.$handleResponseError(error)
        })
    },

    flipCard(index) {
      if (index in this.displayCardBackSide) {
        this.$set(this.displayCardBackSide, index, !this.displayCardBackSide[index])
      } else {
        this.$set(this.displayCardBackSide, index, true)
      }

      // this.displayCardBackSide[data.item.id] = true
    },

    redirectTo(item) {
      // window.location.href = item.static_link
      // window.location.replace(item.static_link)
      const withHttp = url => !/^https?:\/\//i.test(url) ? `http://${url}` : url;
      window.open(withHttp(item.static_link), '_blank')

    },


  },
}
</script>

<style lang="scss" scoped>
.business-card-list-table {
  max-height: 60vh;
  min-height: 20vh;
}

</style>